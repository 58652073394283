
import { Component, Vue, Prop, Emit, Ref, Watch } from "vue-property-decorator";
import "vue-mj-daterangepicker-custom-presets/dist/vue-mj-daterangepicker.css";

@Component
export default class FormDocumentSelect extends Vue {
    @Prop() documents!: Array<{ name: string; id: number }>;

    @Prop({ default: true }) multiple!: boolean;

    @Ref() searchInput!: HTMLInputElement;

    @Prop({ default: () => [] }) value!: number[];

    show: boolean = false;

    selected: number[] = [];

    search = "";

    get clickOutsideConfig() {
        return {
            handler: this.closeUserSelect,
            isActive: this.show,
            capture: true,
        };
    }

    get searchedDocuments() {
        return this.documents.filter((d) => d.name.toLowerCase().includes(this.search.toLowerCase()));
    }

    closeUserSelect() {
        this.show = false;
    }

    toggleSelected(id: number) {
        if (this.multiple) {
            this.handleMultiSelect(id);
        } else {
            this.handleSingleSelect(id);
        }
    }

    handleMultiSelect(id: number) {
        if (this.selected.includes(id)) {
            this.selected.splice(this.selected.indexOf(id), 1);
        } else {
            this.selected.push(id);
        }

        this.handleInput();
    }

    handleSingleSelect(id: number) {
        this.selected = [id];

        this.handleInput();
    }

    onKeyDown({ event }: { event: KeyboardEvent }) {
        if (event.key === "ArrowUp") {
            this.upHandler();
            return true;
        }

        if (event.key === "ArrowDown") {
            this.downHandler();
            return true;
        }

        if (event.key === "Enter") {
            this.enterHandler();
            return true;
        }

        return false;
    }

    upHandler() {
        // this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
    }

    downHandler() {
        // this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    }

    enterHandler() {
        //this.selectItem(this.selectedIndex);
    }

    generateTooltip(content: string) {
        return { content: content, offset: [0, 12], delay: { show: 500, hide: 100 }, placement: "top" };
    }

    @Emit("input")
    handleInput() {
        return this.selected;
    }

    @Watch("show")
    handleShown(isShown: boolean) {
        this.$nextTick(() => {
            if (this.searchInput && isShown) {
                this.searchInput.focus();
            }
        });
    }

    @Watch("value", { immediate: true })
    handleValueChanged(newValue: number[]) {
        this.selected = newValue;
    }
}
